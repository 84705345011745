// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//need to ignore changes
export const environment = {
  production: false,
  apiPath: 'https://api.strongside.app/api/v2/',
  printPath: 'https://api.strongside.app/',
  siteUrl: 'https://strongside.app/',
  // apiPath: 'https://strongside-api-prod.azurewebsites.net/web-api/api/v2/',
  // printPath: 'https://strongside-api-prod.azurewebsites.net/web-api/',
  // siteUrl: 'https://strongside.app/',
  // apiPath: 'https://localhost:44399/api/v2/',
  // printPath: 'https://localhost:44399/',
  // siteUrl: 'http://localhost:4200/',
  firebase: {
    apiKey: "AIzaSyBaK1Z5NWgW09AXp9sNNlMhAYbX6FajLsE",
    authDomain: "strongside-react-playground.firebaseapp.com",
    databaseURL: "strongside-react-playground.firebaseio.com",
    projectId: "strongside-react-playground",
    storageBucket: "strongside-react-playground.appspot.com",
    messagingSenderId: "982681955111",
    appId: "1:982681955111:web:d6da9cad73618f4857d702",
  },
  publicAcCessPlayBookId: '5392518c-787e-4926-b0a3-3aa61f722809',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
