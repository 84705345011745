import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import * as THREE from "three";
export const bounds = {
  minX: -19,
  maxX: 19,
  minZ: -15,
  maxZ: 15,
};

@Component({
  selector: 'app-base-three-js',
  templateUrl: './base-three-js.component.html',
  styleUrls: ['./base-three-js.component.css']
})
export class BaseThreeJsComponent implements OnInit, AfterViewInit {
  @ViewChild("baseThreeJscontent", { static: true })
  container!: ElementRef<HTMLElement>;
  private renderer!: THREE.WebGLRenderer;
  private scene!: THREE.Scene;
  private camera!: THREE.PerspectiveCamera;
  private circles: THREE.Mesh[] = [];
  private controls!: DragControls;
  private aspect: number = 0;
  private width: number = 0;
  private height: number = 0;
  imagePath = "assets/images/football-ground.png";
  positions: any[] = [
    // { x: 0, y: 1, z: -1 },
    // { x: -1.18, y: 1, z: -1.4 },
    // { x: 1.18, y: 1, z: -1.4 },
    // { x: -2.5, y: 1, z: -1.4 },
    // { x: 2.5, y: 1, z: -1.4 },
    // { x: 0.09549999, y: 1, z: -5.26 },
    // { x: 2.54, y: 1, z: -5.9 },
    // { x: -11.16, y: 1, z: -1.66 },
    // { x: -16.23, y: 1, z: -0.9 },
    // { x: 16.23, y: 1, z: -1.66 },
    // { x: 3.62, y: 1, z: -0.9 },
  ];
  @Output() updatedBasePositionData = new EventEmitter();
  @Input() basePosition !: any;
  @Input() oppPosition !: any;
  oppPositions: any[] = [];
  positionRendaration: boolean = false;
  @Input() origin !: string;

  constructor() { }

  ngOnChanges(changes: any) {

    if (changes && changes?.origin) {
      this.origin = changes.origin.currentValue;
    }

    if (changes && changes?.basePosition) {
      this.positions = changes?.basePosition.currentValue;
      //console.log(this.positions, 'p');
    }
    if (this.scene) {
      if (changes && changes?.oppPosition) {
        this.oppPositions = changes?.oppPosition.currentValue;
        if (this.oppPosition && this.oppPosition.length > 0) {
          this.updateShapes();
        }
        //console.log(this.oppPositions, 'opp');
      }
    }
  }

  ngOnInit(): void {
    this.width = this.container.nativeElement.offsetWidth;
    this.height = this.container.nativeElement.offsetHeight;
    this.aspect = this.width / this.height;
  }

  ngAfterViewInit() {
    this.initRenderer();
    this.initScene();
    this.initCamera();
    this.initLight();
    this.loadField();
    this.createShapes();
    if (this.origin !== 'DF') {
      this.initControls();
    }
    this.animate();
  };

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(this.aspect);
    this.renderer.setSize(this.width, this.height);
    this.container.nativeElement.appendChild(this.renderer.domElement);
  };

  initScene() {
    this.scene = new THREE.Scene();
  };

  initCamera() {
    this.camera = new THREE.PerspectiveCamera(45, this.aspect, 1, 500);
    this.camera.position.set(0, 57, 0);
    this.camera.lookAt(0, 0, 0);
  };

  initLight() {
    const light = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
    this.scene.add(light);
  };

  loadField() {
    const loader = new THREE.TextureLoader();
    loader.load(this.imagePath, (texture) => {
      const fieldGeometry = new THREE.PlaneGeometry(50, 70);
      const fieldMaterial = new THREE.MeshBasicMaterial({ map: texture });
      const field = new THREE.Mesh(fieldGeometry, fieldMaterial);
      field.rotation.x = -Math.PI / 2; // Rotate the field to lay flat
      this.scene.add(field);
    });
  };

  createShapes() {
    this.circles = [];
    const circleGeometry = new THREE.CircleGeometry(0.4, 50);
    const circleMaterial = new THREE.MeshBasicMaterial({
      color: "#0467DE",
    });
    const circleMaterial2 = new THREE.MeshBasicMaterial({
      color: "#DE5C04",
    });

    if (!this.positionRendaration) {
      for (let i = 0; i < this.positions?.length; i++) {
        const pos = this.positions[i];
        const circle = new THREE.Mesh(circleGeometry, circleMaterial);
        circle.position.set(pos.x, pos.y, pos.z * -1);
        circle.rotation.x = -Math.PI / 2;
        this.circles.push(circle);
        this.scene.add(circle);
      }
      this.positionRendaration = true;
    }

    for (let i = 0; i < this.oppPositions?.length; i++) {
      const pos = this.oppPositions[i];
      const circle = new THREE.Mesh(circleGeometry, circleMaterial2);
      circle.position.set(pos.x, pos.y, pos.z * -1);
      circle.rotation.x = -Math.PI / 2;
      this.circles.push(circle);
      this.scene.add(circle);
    }
  };

  initControls() {
    this.controls = new DragControls(
      this.circles,
      this.camera,
      this.renderer.domElement
    );

    this.controls.addEventListener("drag", (event) => {
      const object = event.object;
      object.position.x = Math.max(
        bounds.minX,
        Math.min(bounds.maxX, object.position.x)
      );
      object.position.z = Math.max(
        bounds.minZ,
        Math.min(bounds.maxZ, object.position.z)
      );
    });

    this.controls.addEventListener("dragstart", (event) => {
      const mesh = event.object as THREE.Mesh; // Cast to THREE.Mesh
      if (mesh.material instanceof THREE.Material) {
        mesh.material.opacity = 0.5;
      }
    });

    this.controls.addEventListener("dragend", (event) => {
      const mesh = event.object as THREE.Mesh; // Cast to THREE.Mesh
      if (mesh.material instanceof THREE.Material) {
        mesh.material.opacity = 1;
      }

      var _updatedPostion: any[] = [];

      this.circles.forEach(element => {
        _updatedPostion.push({
          x: element.position.x,
          y: element.position.y,
          z: element.position.z
        });
      });

      this.updatedBasePositionData.emit(_updatedPostion);
    });
  };

  animate() {
    setTimeout(() => {
      this.renderer.setAnimationLoop(() => {
        this.renderer.render(this.scene, this.camera);
      });
    }, 1000)
  };

  updateShapes() {
    this.createShapes();
    this.animate();
  };
}
