import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../data/services/auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-player-reg',
  templateUrl: './player-reg.component.html',
  styleUrls: ['./player-reg.component.css']
})
export class PlayerRegComponent implements OnInit {
  public playerRegForm: FormGroup;
  public validate = false;

  constructor(
    private fb: FormBuilder,
    public toster: ToastrService,
    private authenticationService: AuthenticationService,
    private loader: LoaderService
  ) {
    this.playerRegForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      hudlLink: ['', Validators.required],
      twitterLink: ['', Validators.required],
      maxprepsLink: ['', Validators.required],
    });
  }

  onMobileChange(evt: any) {
    if (evt.code !== 'Backspace') {
      let event = this.playerRegForm.controls['phone'].value;
      if (event) {
        let newVal = event.replace(/\D/g, '');
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (newVal.length <= 10) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
          newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }

        this.playerRegForm.controls['phone'].setValue(newVal);
      }
      else {
        this.playerRegForm.controls['phone'].setValue('');
      }
    }
    else {
      this.playerRegForm.controls['phone'].setValue(evt.target.value);
    }
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.playerRegForm.invalid) {
      this.validate = !this.validate;
      return;
    }

    this.loader.isLoading.next(true);
    const data = this.playerRegForm.value;
    data.twitterLink = "https://twitter.com/" + data.twitterLink;

    this.authenticationService.information(data).subscribe(resp => {
      if (resp && resp.success) {
        this.loader.isLoading.next(false);
        Swal.fire('Joined waiting list.', resp.responseMsg, 'success'
        ).then((result) => {
          this.playerRegForm.reset();
        });
      }
      else {
        this.toster.error(resp.responseMsg);
      }
    },
      error => {
        this.loader.isLoading.next(false);
        this.toster.error("Failed to Save.");
      });
  }
}
