import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../shared/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-app-download',
  templateUrl: './app-download.component.html',
  styleUrls: ['./app-download.component.css']
})
export class AppDownloadComponent implements OnInit {
  type: any;

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loaderService.isLoading.next(true);
    this.type = this.route.snapshot.paramMap.get('_type');
    if (this.type === 'mac' || this.type === 'Mac') {
      //window.open("https://strongsideupload.blob.core.windows.net/application-files/Strongside.pkg");
      window.open("https://fieldvisionupload.s3.us-east-1.amazonaws.com/Desktop-App/Stongside-v4.11-Mac.app.zip")
    }
    else {
      //window.open("https://strongsideupload.blob.core.windows.net/application-files/Strongside.zip")
      window.open("https://fieldvisionupload.s3.us-east-1.amazonaws.com/Desktop-App/Strongside+4.11.0.exe");
    }
    this.loaderService.isLoading.next(false);
    this.router.navigate(["/dashboard/default"]);
  }

}
