<div class="modal-body">
  <button type="button" class="close" aria-label="Close" (click)="closeModal('dismiss')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="">
    <img src="{{captureImg}}" alt="capture" title="capture" width="100%" height="100%">
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="saveImg(captureImg)">Save Screenshot</button>
  <button type="button" class="btn btn-danger" (click)="closeModal('dismiss')">Cancel</button>
</div>