<!-- <div #threeJscontent style="height: calc(100vh - 100px); width: 800px;"></div> -->
<!-- <div class="modal-header" *ngIf="fromParent">
  <h5 class="modal-title">Strongside Admin</h5>
  <button type="button" class="close" (click)="closeModal('dismiss')">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
<div class="modal-body threeModal">
  <button type="button" class="close" (click)="closeModal('dismiss')" *ngIf="fromParent">
    <span aria-hidden="true">&times;</span>
  </button>
  <div #threeJscontent style="width: 80%; height: 100vh;"></div>
  <div class="d-flex justify-content-end align-items-center flex-wrap p-2" *ngIf="fromParent">
    <button type="button" class="btn btn-primary mr-2" (click)="update()">
      <i class="fa fa-save mr-1"></i> Update
    </button>
    <button type="button" class="btn btn-primary mr-2" (click)="capture_ground()">
      <i class="fa fa-camera"></i> Capture
    </button>
    <button type="button" class="btn btn-danger" (click)="closeModal('no')">Close</button>
  </div>
</div>


<!-- <div class="modal-footer" *ngIf="fromParent">
  <button type="button" class="btn btn-outline-dark" (click)="capture_ground()">
    <i class="fa fa-camera"></i> Capture
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="closeModal('no')">Close</button>
</div> -->
<!--Capture Image View modal -->
<!-- <ng-template #captureImgViewcontent let-c="close" let-d="dismiss" let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="closeModal(modal)">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="boxvideo">
      <img src="{{cartureImg}}" alt="capture" title="capture" width="100%" height="100%">
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="saveImg(modal)">Save Screenshot</button>
    <button type="button" class="btn btn-danger" (click)="modal.close()">Cancel</button>
  </div>
</ng-template> -->
<!--  -->