import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormationService } from 'src/app/data/services/offense';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  @Input() fromParentPrevieData: any;
  captureImg = "";
  originId: string = "";
  origin: string = "";

  constructor(private loaderService: LoaderService,
    private formationService: FormationService,
    public toster: ToastrService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.fromParentPrevieData) {
      this.originId = this.fromParentPrevieData.originId;
      this.origin = this.fromParentPrevieData.origin;
      this.captureImg = this.fromParentPrevieData.captureImg;
    }
  }

  saveImg(previewImg: any) {
    let capFileName = "";
    capFileName = 'ThreeJsImage';
    const imageBlob = this.dataURItoBlob(previewImg);
    const imageFile = new File([imageBlob], capFileName + '_capture.png', { type: 'image/png' });

    let formData = new FormData();
    formData.append('isWebUpload', 'true');
    formData.append('files[' + 0 + ']', imageFile);

    this.loaderService.isLoading.next(true);
    if (this.origin == 'Formation') {
      formData.append('formationId', this.originId);
      this.formationService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
        this.modalService.dismissAll("");
        this.router.navigate(["/offense/playbook/detail", { ref: 'tab-3' }]);
      });
    }
  };

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };

  dataURItoBlob(dataURI: any) {
    var imageData = dataURI.toString();
    var byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: undefined
    });
    return blob;
  };
}
