import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../data/services/auth/authentication.service';
import { PackagePaymentService } from '../data/services/auth/package-payment.service';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.css']
})
export class PackageDetailComponent implements OnInit {
  public packages: any[] = [];
  public activeStatusArray = [true, false, false];
  public packageId: any;
  public package: any;
  public registerForm: FormGroup;
  public show: boolean = false;
  public showConfirm: boolean = false;
  public accountId: any;
  public success: any;
  constructor(
    private packagePaymentService: PackagePaymentService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public toster: ToastrService,
  ) {
    this.registerForm = fb.group({
      name: ["", Validators.required],
      lastName: ["", Validators.required],
      organizationName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      mobile: [""],
      password: ["", [Validators.required, Validators.minLength(8), Validators.pattern("(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}")]],
      confirmPassword: ["", Validators.required],
    },
      { validators: this.checkPasswords });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  onMobileChange(evt: any) {
    if (evt.code !== 'Backspace') {
      let event = this.registerForm.controls['mobile'].value;
      if (event) {
        let newVal = event.replace(/\D/g, '');
        if (newVal.length === 0) {
          newVal = '';
        } else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (newVal.length <= 10) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
          newVal = newVal.substring(0, 10);
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }

        this.registerForm.controls['mobile'].setValue(newVal);
      }
      else {
        this.registerForm.controls['mobile'].setValue('');
      }
    }
    else {
      this.registerForm.controls['mobile'].setValue(evt.target.value);
    }
  }

  ngOnInit(): void {
    this.success = this.route.snapshot.paramMap.get('success');
    if (this.success === "true" || this.success === "false") {
      this.success = this.success === "true" ? true : false;
      this.activeStatusArray = [false, false, true];
    }
    this.packagePaymentService.packageAllSubscripton().subscribe(resp => {
      this.packages = resp;
    });
  }

  p_buy(id: string) {
    this.loaderService.isLoading.next(true);
    this.packageId = id;
    let index = this.packages.findIndex(element => { return element.id === id });
    if (index > -1) {
      this.activeStatusArray = [false, true, false];
      this.package = this.packages[index];
      this.loaderService.isLoading.next(false);
    }
  };

  get f() {
    return this.registerForm.controls;
  };

  showPassword() {
    this.show = !this.show;
  };
  showConfirmPassword() {
    this.showConfirm = !this.showConfirm;
  };

  register() {
    if (this.registerForm.invalid) {
      return;
    }
    const signUpData = this.registerForm.value;
    //console.log(signUpData);
    // window.location.href = "https://localhost:44399/Payments?packageId=" + this.packageId + "&accountId=" + '00000000-0000-0000-0000-000000000000';
    this.authenticationService.signUp(signUpData).subscribe(resp => {
      if (resp && resp.success) {
        this.accountId = resp.response.accountId;
        window.location.href = environment.printPath + "Payments?packageId=" + this.packageId + "&accountId=" + this.accountId;
        // this.toster.success(resp.responseMsg);
      }
      else {
        this.toster.error(resp.responseMsg);
      }
    },
      error => {
        this.toster.error("Failed to create account.");
      });
  };

  go_login() {
    this.router.navigate(['/auth/login']);
  };
}
